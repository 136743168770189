import React, { useEffect, useCallback, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getSecondStart } from '../utils';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';

import { updateRateIntervalState } from '#state';
import {
  setWsActions,
  authorizedState,
  tradingStatusState,
  tradeKeysState,
  tradesDetailsState,
  tradesUpdateState,
  levelsUpState,
  levelsDownState,
} from '#state/trades';

// const wsBase = 'wss://bm.elmeno.dev'
const isDev = process.env.NODE_ENV !== 'production'
const apiEnv = process.env.REACT_APP_ENV || 'TEST';
const wsBase = (apiEnv === 'PROD')
  ? 'wss://bpcm1.elmeno.dev'
  // : 'wss://botpro.elmeno.dev'
  : 'wss://bot.elmeno.dev'
console.log('wsBase', wsBase, `REACT_APP_BASE_DOMAIN_${apiEnv}`);

export const WSAPI = props => {
  const [tradesUpdated, setTradesUpdated] = useState(false)

  const [authorized, setAuthorized] = useRecoilState(authorizedState)
  const updateRateInterval = useRecoilValue(updateRateIntervalState)
  const tradesDetails = useRecoilValue(tradesDetailsState)
  const [tradeKeys, setTradeKeys] = useRecoilState(tradeKeysState)
  const setTradesUpdate = useSetRecoilState(tradesUpdateState)
  const setTradingStatus = useSetRecoilState(tradingStatusState)
  const setLevelsUp = useSetRecoilState(levelsUpState)
  const setLevelsDown = useSetRecoilState(levelsDownState)

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(wsBase, {
    shouldReconnect: closeEvent => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: event => {
      console.log('WSAPI', 'opened')
      sendJsonMessage({ action: 'auth', token: window.apiKey })
    },
    onClose: event => {
      console.log('WSAPI', 'closed')
      setAuthorized(false)
      setWsActions(null)
    },
    onMessage: event => {
      const data = JSON.parse(event.data)
      // console.log('WSAPI message', data)

      if (data.action === 'auth' && !data.error) {
        setAuthorized(true)
      }

      if (data.action === 'paperStates') {
        const { longs, shorts } = data
        setTradeKeys({
          longs,
          shorts
        })
      }

      if (data.action === 'paperState') {
        const { key, state } = data
        const stateJson = JSON.parse(state)
        tradesDetails.set(key, stateJson)
        setTradesUpdated(true)
      }

      if (data.action === 'cacheUpdate') {
        const { key } = data
        // console.log('cacheUpdate', key)
        const parts = key.split(':')
        const side = parts[0]
        const arrkey = side === 'LONG' ? 'longs' : 'shorts'
        const temp = (tradeKeys && tradeKeys[arrkey]) || []
        const up = [...temp, key]
        setTradeKeys({ ...tradeKeys, [arrkey]: up })
        sendJsonMessage({ action: 'paperState', key })
      }

      if (data.action === 'cacheRemove') {
        const { key } = data
        const details = tradesDetails.get(key)
        console.log('cacheRemove', key, details)

        const parts = key.split(':')
        const side = parts[0]
        const arrkey = side === 'LONG' ? 'longs' : 'shorts'
        const filtered = tradeKeys[arrkey].filter(k => k !== key)
        setTradeKeys({ ...tradeKeys, [arrkey]: filtered })

        tradesDetails.delete(key)
        setTradesUpdated(true)
      }

      if (data.action === 'status') {
        const { trading, breakout, bounce, long, short } = data
        console.log('trading status', data)
        setTradingStatus({ trading, breakout, bounce, long, short })
      }

      if (data.action === 'closestLevels') {
        // console.log('closestLevels', data)
        if (data.isup) {
          // aggregate results from format [sym1, score1, sym2, score2, ...]
          // to [{ symbol: sym1, score: score1 }, ...]
          const results = []
          for (let i = 0; i < data.results.length; i += 2) {
            results.push({
              symbol: data.results[i],
              score: parseFloat(data.results[i + 1])
            })
          }
          setLevelsUp(results)
        } else {
          const results = []
          for (let i = 0; i < data.results.length; i += 2) {
            results.push({
              symbol: data.results[i],
              score: parseFloat(data.results[i + 1])
            })
          }
          setLevelsDown(results)
        }
      }

      if (data.action === 'reloadAccount') {
        console.log('reloadAccount', data)
      }
    }
  });

  useEffect(() => {
    if (authorized) {
      console.log('WSAPI', 'authorized')
      setWsActions({
        sendJsonMessage
      })
      sendJsonMessage({ action: 'status' })
      sendJsonMessage({ action: 'paperStates' })
    }
  }, [authorized, sendJsonMessage]);

  useEffect(() => {
    if (tradeKeys) {
      //console.log('WSAPI', 'paperStates')
      const longs = tradeKeys?.longs || []
      const shorts = tradeKeys?.shorts || []
      const tradesKeys = [...longs, ...shorts]

      for (const mk of tradesDetails.keys()) {
        if (!tradesKeys.includes(mk)) {
          tradesDetails.delete(mk)
          setTradesUpdated(true)
        }
      }

      for (let i = 0; i < tradesKeys.length; i++) {
        const key = tradesKeys[i]
        if (!tradesDetails.has(key)) {
          setTimeout(() => {
            sendJsonMessage({ action: 'paperState', key })
          }, 10 * i);
        }
      }
    }
  }, [tradeKeys, tradesDetails, sendJsonMessage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tradesUpdated) {
        setTradesUpdate(p => p + 1)
        setTradesUpdated(false)
      }
    }, updateRateInterval)
    return () => {
      clearInterval(interval)
    }
  }, [updateRateInterval, tradesUpdated, setTradesUpdate]);

  return (
    <div>
      {/* <button
        onClick={handleClickSendMessage}
        disabled={readyState !== ReadyState.OPEN}
      >
        Click Me to send 'Hello'
      </button> */}
      {/* <div>The WebSocket is currently {connectionStatus}</div> */}
    </div>
  );
};

export default WSAPI;
