
import { atom, selector } from 'recoil';

// stats
export const statsModeState = atom({
  key: 'statsModeState',
  default: 'paper',
});
export const statsFilterEventState = atom({
  key: 'statsFilterEventState',
  default: 'close',
});
export const statsFilterStratState = atom({
  key: 'statsFilterStratState',
  default: 'all',
});
export const statsFilterWRState = atom({
  key: 'statsFilterWRState',
  default: 'disabled',
});
export const statsStartTimeState = atom({
  key: 'statsStartTimeState',
  default: 0,
});
export const statsEndTimeState = atom({
  key: 'statsEndTimeState',
  default: Infinity,
});

// state
export const stateFilterStratState = atom({
  key: 'stateFilterStratState',
  default: 'all',
});
export const stateFilterRealState = atom({
  key: 'stateFilterRealState',
  default: 'all',
});
export const stateFilterSideState = atom({
  key: 'stateFilterSideState',
  default: 'all',
});

// ************************************************************
// charts
// ************************************************************

export const tradeChartHeightState = atom({
  key: 'tradeChartHeightState',
  default: 450,
});
export const tradeChartWidthState = atom({
  key: 'tradeChartWidthState',
  default: '100%',
});

export const clusterChartHeightState = atom({
  key: 'clusterChartHeightState',
  default: 450,
});
export const clusterChartWidthState = atom({
  key: 'clusterChartWidthState',
  default: '100%',
});

export const candleChartHeightState = atom({
  key: 'candleChartHeightState',
  default: 450,
});
export const candleChartWidthState = atom({
  key: 'candleChartWidthState',
  default: 900,
});
export const indicatorsChartHeightState = atom({
  key: 'indicatorsChartHeightState',
  default: 450,
});
export const indicatorsChartWidthState = atom({
  key: 'indicatorsChartWidthState',
  default: 900,
});