import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { stateFilterStratState } from '#state/gui'

const factors = ['all', 'breakout', 'bounce'];

export function StateFilterStrat() {
  const [stratFilter, setStratFilter] = useRecoilState(stateFilterStratState);

  useEffect(() => {
    localforage.setItem('stateFilterStratState', stratFilter);
  }, [stratFilter]);

  const handleFactorChange = (event) => {
    setStratFilter(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="StateFilterStrat">Strategy</InputLabel>
      <Select
        labelId="StateFilterStrat"
        size='small'
        sx={{ minWidth: '5rem' }}
        value={stratFilter}
        onChange={handleFactorChange}
      >
        {factors.map((symbol) => (
          <MenuItem value={symbol} key={symbol}>
            {symbol}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}