import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { stateFilterSideState } from '#state/gui'

const options = ['all', 'LONG', 'SHORT'];

export function StateFilterSide() {
  const [filter, setFilter] = useRecoilState(stateFilterSideState);

  useEffect(() => {
    localforage.setItem('stateFilterSideState', filter);
  }, [filter]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="StateFilterSide">Side</InputLabel>
      <Select
        labelId="StateFilterSide"
        size='small'
        value={filter}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}